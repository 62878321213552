import { useLocale } from '../../../contexts/LocalizationContext';
import { displayCurrencyValue } from '../../../helper/helper';
import { BackgroundIcon } from '../../Icons/Icons';
import {
  PlusLogoContainer,
  PlusPriceCard,
  PlusPriceCardText,
} from '../ProductCardGStyle';

interface Props {
  price: string | number;
  currencySymbol: string | undefined;
  multiplier: number;
  product: any;
}

function PlusPricingStrip({
  price,
  currencySymbol,
  multiplier,
  product,
}: Props) {
  const { messages } = useLocale();
  const { from_msg } = messages || {};

  return (
    <PlusPriceCard>
      <PlusLogoContainer>
        <BackgroundIcon
          url="/icons/plus-logo-circular.svg"
          width="16px"
          height="16px"
          margin="0 0"
        />
      </PlusLogoContainer>
      <PlusPriceCardText
        textSize={
          displayCurrencyValue(
            Number(price) - Number(price) * Number(product.plusDiscount),
            currencySymbol,
            multiplier
          ).length > 10
            ? 'small'
            : 'normal'
        }
      >
        <span>{from_msg} </span>
        {displayCurrencyValue(
          Number(price) - Number(price) * Number(product.plusDiscount),
          currencySymbol,
          multiplier
        )}
      </PlusPriceCardText>
    </PlusPriceCard>
  );
}

export default PlusPricingStrip;
