import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

interface Props {
  page: 'home' | undefined;
}

export const ProductCardContainer = styled.a<Props>`
  transition: 0.3s all ease-in-out;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  border-radius: 10px;
  border: 1px solid ${COLORS.greyLight};
  max-width: 250px;

  @media (min-width: 768px) {
    &:hover {
      cursor: pointer;
      border: 1px solid black;
    }
  }
  #product-price-container {
    color: black;
    background: ${COLORS.white};
    border-radius: 0 0 10px 10px;
  }
  #card-right-arrow {
    display: none;
  }
  @media (min-width: 768px) {
    &:hover {
      #product-price-container {
        background: black;
        color: ${COLORS.white};
      }
      #card-right-arrow {
        display: block;
      }
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-right: 16px;
    max-width: 240px;
    min-width: 140px;
    width: ${(props) => (props.page === 'home' ? '25%' : '')};
  }
`;

export const NewImageContainer = styled.div`
  display: grid;
  overflow: hidden;
  position: relative;
  border-radius: 10px 10px 0 0;
  height: 280px;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 186px;
  }
`;

export const NewImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-bottom: 0;
  }
`;

export const NewImageTag = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const NewContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 8px;
  }
`;

export const NewContentTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* height: 35px; */
  font-size: 0.9rem;
  font-size: 16px;
  font-family: Onest-SemiBold;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 6px;
  }
`;

export const ProductInfoLeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ProductInfoRightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ProductInfoTitle = styled.div`
  font-family: Onest-Regular;
  font-size: 0.75rem;

  line-height: 16px;
  color: #717171;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 0.6rem;
    line-height: 10px;
  }
`;

export const ProductInfoContent = styled.div`
  font-style: normal;
  font-family: Onest-Bold;
  font-size: 0.85rem;
  line-height: 20px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 10px;
    line-height: 13px;
  }
`;

export const ProductPriceContainer = styled.div`
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${COLORS.greyLight};
  height: 40px;
  border-radius: 0 0 6px 6px;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 27px;
  }
`;

export const BuyNowText = styled.div`
  font-size: 12px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 0.6rem;
    display: none;
  }
`;

interface PriceProps {
  len?: number;
}

export const ProductPrice = styled.div<PriceProps>`
  font-style: normal;
  font-family: Onest-Bold;
  /* font-size: 1.1rem; */
  font-size: ${(props) => (props.len && props.len > 12 ? '0.9rem' : '1.1rem')};

  margin-top: 0;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: ${(props) =>
      props.len && props.len > 12 ? '0.7rem' : '0.9rem'};
  }
`;

export const BookmarkContainer = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
`;

export const NotifyMeContent = styled.div`
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 0.9rem;
    font-weight; 600;
  }
`;

export const SpecialPlusPriceFooterContainer = styled.div`
  display: flex;
  border: 1px solid ${COLORS.drifflePlus};
  padding: 12px 8px 8px;
  position: relative;
  border-radius: 8px;
  gap: 4px;
  flex-direction: column;
`;

export const SpecialPlusPriceFooterPlusLogo = styled.div`
  width: 52px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid ${COLORS.drifflePlus};
  background: ${(props) => props.theme.palette.background.bg1};
  position: absolute;
  top: -12px;
  right: 12px;
`;

export const SpecialPlusPriceBuyBtn = styled.div`
  width: 100%;
  > button {
    height: 36px;
    background: ${COLORS.drifflePlus};
    font-size: ${(props) =>
      (props.children?.toString().replace('.', '').replace(',', '')?.length ??
        0) > 15
        ? '0.75rem'
        : '.875rem'};
    :hover {
      background: ${COLORS.drifflePlusText};
    }
  }
`;

export const SpecialPlusPriceMrpStrikeText = styled.div`
  font: 0.75rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    text-decoration: line-through;
  }
`;

export const SpecialPlusPriceTextRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;
