import styled, { css, keyframes } from 'styled-components';
import { ProductCardLocation } from '../../Interface/ProductCardInterface';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

interface Props {
  page?: ProductCardLocation | undefined;
}

export const CardWrapper = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 200px;
  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 160px;
    min-width: 160px;
  }
`;

export const ProductCardWrapper = styled.div<Props>`
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  min-width: 158px;
  background: ${(props) => props.theme.palette.background.bg1};
  :hover {
    border: 1px solid ${(props) => props.theme.palette.border.b2};
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.palette.background.bg3
        : props.theme.palette.background.bg1};
    box-shadow: ${(props) =>
      props.theme.mode === 'dark' ? 'none' : props.theme.shadows.s2};
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 240px;
    min-width: 140px;
    flex: 0 0 160px;
    width: ${(props) => (props.page === 'home' ? '25%' : '')};
    margin-right: ${(props) => (props.page === 'home' ? '16px' : '')};
  }
  outline: none;
  transition: 0.2s;
`;

export const ProductCardContainer = styled.div`
  // border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  &:hover {
    // #product-card-image {
    //   transform: translateY(-6px);
    //   border-radius: ${(props) => props.theme.palette.borderRadius.b2};
    //     @media(max-width: ${WIDTH.mobileMax}){
    //       border-radius: ${(props) => props.theme.palette.borderRadius.b2};
    //       transform: none;
    //     }
    //   }
    // #product-card-image-container{
    // border-radius: ${(props) => props.theme.palette.borderRadius.b2};
    // }
    // }
  }
  // &:active {
  //   #product-card-image {
  //     transform: scale(1.05);
  //   }
  // }
`;

export const ImageContainer = styled.div`
  flex: 0 1 280px;
  // max-height: 248px;
  position: relative;
  // padding-top: 248px;
  width: 100%;
  img {
    // border-radius: 8px 8px 0 0;
    transition: 0.5s;
  }
  > span {
    // border-radius: 8px 8px 0 0;
  }
  transition: 0.5s;
`;

export const OriginalImageContainer = styled.div`
  // border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  height: auto;
  width: 100%;
  position: relative;
`;

export const OOSOverlay = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-radius: 8px 8px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000 100%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s;
  padding: 8px;
`;

export const NotifyMeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  background: #ffffff4d;
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.24);
  color: #fff;
  font: 0.875rem/1.125rem Onest-Bold;
  cursor: pointer;
  :hover {
    background: #ffffff66;
  }
`;

export const NotifyMeButtonActive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.24);
  color: #000;
  font: 0.875rem/1.125rem Onest-Bold;
  cursor: pointer;
  :hover {
    background: #fff;
  }
`;

export const DiscountTagWrapper = styled.div`
  position: absolute;
  right: -16px;
  top: -12px;
  z-index: 10;
  > div {
    position: relative;
    > p {
      position: absolute;
      top: 10px;
      right: 12px;

      margin: 0;
      font: 1rem/1rem Onest-SemiBold;
      color: ${COLORS.white};
    }
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    // display: none;
  }
`;

export const ImageBlur = styled.img`
  filter: blur(20px);
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  height: 20px;
  left: 25px;
  margin-auto;
  width: calc(100% - 50px);
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s all ease;
  padding: 12px 12px 12px;
  height: 100%;
  border-radius: 0 0 8px 8px;
  flex: 1;

  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 8px;
  }
`;

export const ProductTitle = styled.span`
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  font: 0.9735rem/1.125rem Onest-Bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
  min-height: 36px;
  max-height: 36px;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const ProductTitleNew = styled.span`
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  font: 0.875rem/1.125rem Onest-Medium;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
  min-height: 36px;
  max-height: 36px;
  color: ${(props) => (props.theme.mode === 'dark' ? '#faf9f6' : '#000000')};
`;

export const TitleSpan = styled.span``;

export const ProductDetails = styled.div``;

export const ProductInfo = styled.div`
  font: 0.875rem/1.125rem Onest-Bold;
  color: ${(props) => (props.theme.mode === 'dark' ? '#48bdff' : '#359dff')};
  text-transform: uppercase;
  height: 100%;
  margin-bottom: 8px;
`;

export const ProductBottomRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  flex: 1;
`;

export const DiscountBox = styled.div`
  height: 24px;
  width: 48px;
  background: #da0e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font: 0.75rem/1rem Onest-SemiBold;
  color: #fff;

  @media (max-width: ${WIDTH.mobileMax}) {
    width: 40px;
    height: 20px;
  }
`;

export const PlusDiscountBox = styled.div`
  height: 24px;
  width: 48px;
  border: 1px solid ${COLORS.drifflePlus};
  background: ${COLORS.drifflePlus + '33'};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font: 0.75rem/1rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};

  @media (max-width: ${WIDTH.mobileMax}) {
    width: 40px;
    height: 20px;
  }
`;

export const PlusProductPrice = styled.div`
  font: ${(props) =>
    (props.children?.toString().replace('.', '').replace(',', '')?.length ??
      0) < 6
      ? '1.375rem/1.5rem Onest-SemiBold'
      : (props.children?.toString().replace('.', '').replace(',', '')?.length ??
          0) < 10
      ? '1rem/1.25rem Onest-SemiBold'
      : '0.875rem/1rem Onest-SemiBold'};

  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: ${(props) =>
      (props.children?.toString().replace('.', '').replace(',', '')?.length ??
        0) < 6
        ? '1rem/1.25rem Onest-SemiBold'
        : (props.children?.toString().replace('.', '').replace(',', '')
            ?.length ?? 0) < 9
        ? '0.75rem/1rem Onest-SemiBold'
        : '0.75rem/1rem Onest-SemiBold'};
  }
`;

export const ProductPrice = styled.div`
  font: ${(props) =>
    (props.children?.toString().replace('.', '').replace(',', '')?.length ??
      0) < 9
      ? '1.375rem/1.5rem Onest-SemiBold'
      : (props.children?.toString().replace('.', '').replace(',', '')?.length ??
          0) < 12
      ? '1rem/1.25rem Onest-SemiBold'
      : '0.625rem/1.25rem Onest-SemiBold'};

  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: ${(props) =>
      (props.children?.toString().replace('.', '').replace(',', '')?.length ??
        0) < 9
        ? '1rem/1.25rem Onest-SemiBold'
        : (props.children?.toString().replace('.', '').replace(',', '')
            ?.length ?? 0) < 12
        ? '0.75rem/1rem Onest-SemiBold'
        : '0.75rem/1rem Onest-SemiBold'};
  }
`;

export const SoldOutMsg = styled.div`
  color: ${(props) => props.theme.palette.error.dark};
  font: 1.125rem/1.375rem Onest-Bold;
`;

export const ImageTile = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 6px 6px 0 0;
`;

export const IconContainer = styled.div`
  height: 34px;
  width: 34px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
`;

export const Sticker = styled.div`
  background: ${COLORS.primary};
  opacity: 0.9;
  border-radius: 6px 0px 0px 6px;
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: 106px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ProductSoldOutPill = styled.div`
  color: #ea3d56;
  font-family: Onest-SemiBold;
  font-size: 14px;
  text-align: center;
  margin-top: 18px;
  padding: 0;
  height: 40px;
  border-radius: 6px;
  line-height: 40px;
`;
export const ProductSoldOutPillMobile = styled.div`
  font-size: 12px;
  text-align: center;
  // padding: 8px;
  width: 100px;
  margin-top: 20px;
  display: flex;
  // justify-content: center;
  align-items: center;
  color: #ea3d56;
  gap: 6px;
`;
export const ProdutSoldOutIcon = styled.div`
  width: 20px;
  height: 20px;
  // background-image: url(./icons/soldout.svg);
  // background-repeat: no-repeat;
  // background-position: center;
  // color: red;
  background-color: #ea3d56;
  -webkit-mask-image: url(./icons/soldout.svg);
  mask-image: url(./icons/soldout.svg);
  mask-repeat: no-repeat;
  mask-position: center;
`;

// export const ProductInfo = styled.div`
//   display: flex;
//   width: 100%;
// `;

export const ProductInfoLeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ProductInfoRightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ProductInfoTitle = styled.div`
  font-family: Onest-Regular;
  font-size: 12px;
  line-height: 16px;
  color: #717171;
`;

export const ProductInfoContent = styled.div`
  font-style: normal;
  font-family: Onest-Bold;
  font-size: 15px;
  line-height: 20px;
`;

export const ProductPriceContainer = styled.div`
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${COLORS.greyLight};
  height: 40px;
  border-radius: 0 0 6px 6px;
`;

export const BuyNowText = styled.div`
  font-size: 12px;
`;

export const BookmarkContainer = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
`;

export const PlatformName = styled.div`
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const PreOrderTag = styled.div`
  height: 22px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.warning.main};
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px;
  width: fit-content;
  white-space: nowrap;
  & > p {
    color: ${(props) => props.theme.palette.warning.main};
    font-size: 12px;
    line-height: 14px;
    font-family: Onest-SemiBold;
    white-space: no-wrap;
  }
`;

export const PreOrderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  white-space: no-wrap;
  gap: 4px;
  & > p {
    font-size: 12px;
    line-height: 16px;
    white-space: no-wrap;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  padding: 0 12px 12px;
  margin: -12px 0 0;
  width: 100%;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity:1;
    transform: translateY(0);
  }
`;
const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const HoverBtnWrapper = styled.div<{ show: boolean }>`
  width: 100%;
  height: 78px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? `linear-gradient(180deg, #17171700 0%, #171717 100%)`
      : `linear-gradient(180deg, #17171700 0%,#fafafa 100%)`};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(100%);
  padding-top: 14px;
  border-radius: 0 0 8px 8px;
  transition: transform 0.3s ease-in-out;
  ${(props) =>
    props.show
      ? css`
          animation: ${slideIn} 0.3s forwards;
        `
      : css`
          animation: ${slideOut} 0.3s forwards;
        `}
`;

export const AddToCartBtn = styled.button`
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #4885ff;
  border-radius: 100px;
  margin: 0 auto;
  // &:hover{
  //    background:${(props) => props.theme.palette.button.primary.hover};
  // }
  &:active {
    ${(props) => (props.disabled ? '' : `transform: scale(0.95);`)}
  }
  color: #ffffff;
  font-size: 14px;
  font-family: Onest-Bold;
`;

export const PriceFromText = styled.div`
  font: 0.75rem/0.75rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  margin-bottom: 2px;
`;

export const PlusPriceCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.drifflePlus};
  background: ${COLORS.drifflePlus + '22'};
  height: 32px;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  gap: 6px;
  margin-bottom: 8px;
`;

export const PlusLogoContainer = styled.div`
  height: 32px;
  width: 16px;
  display: flex;
  align-items: center;
`;

export const PlusPriceCardText = styled.div<{
  textSize: 'small' | 'normal';
}>`
  span {
    font: 0.75rem/1rem Onest-Medium;
  }
  font: ${(props) =>
      props.textSize === 'small' ? '0.75rem/1rem' : '0.875rem/1.125rem'}
    Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: ${(props) =>
        props.textSize === 'small' ? '0.625rem/0.875rem' : '0.75rem/1rem'}
      Onest-Medium;
    span {
      font: 0.625rem/0.875rem Onest-Medium;
    }
  }
`;
