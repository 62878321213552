import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CountryListInterface } from '../../Interface/GlobalInterface';
import { HomepageCollectionsProductCardInterface } from '../../Interface/HomepageCollectionsInterface';
import {
  ProductCardInterface,
  ProductCardLocation,
  ProductSectionCardInterface,
} from '../../Interface/ProductCardInterface';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import { useNotifyMeProducts } from '../../contexts/NotifyMeProductsContext';
import {
  displayCurrencyValue,
  getDiscount,
  getRegionFromRegionId,
} from '../../helper/helper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { MaskIcon } from '../Icons/Icons';
import LoaderCircle from '../LoaderDots/LoaderCircle';
import TooltipWithArrow from '../ToolTipWithArrow/TooltipWithArrow';
import {
  CardWrapper,
  DetailsContainer,
  DiscountBox,
  ImageContainer,
  NotifyMeButton,
  NotifyMeButtonActive,
  OOSOverlay,
  OriginalImageContainer,
  PriceFromText,
  ProductBottomRow,
  ProductCardContainer,
  ProductCardWrapper,
  ProductInfo,
  ProductPrice,
  ProductTitleNew,
  SoldOutMsg,
} from './ProductCardGStyle';
import PlusPricingStrip from './components/PlusPricingStrip';
import SpecialPlusPriceFooter from './components/SpecialPlusPriceFooter';
import useAddToCartByProductSku from './hooks/useAddToCartByProductSku';

const HoverAddToCartBtn = dynamic(
  () => import('./components/HoverAddToCartBtn'),
  { ssr: false }
);

const Link = dynamic(() => import('next/link'), { ssr: true });

interface Props {
  product:
    | ProductSectionCardInterface
    | ProductCardInterface
    | HomepageCollectionsProductCardInterface
    | null;
  widthType?: 'fixed' | 'auto';
  page?: ProductCardLocation;
  index?: number;
  clickBubble?: () => void;
  storeId?: string | null | undefined | string[];
  showAddToCartBtn?: boolean;
  recommendationId?: string | null;
  scenario?: string;
  plusType?: boolean;
}

const ProductCardG = ({
  product,
  page,
  clickBubble,
  storeId,
  showAddToCartBtn = false,
  scenario,
  widthType = 'auto',
  plusType,
  ...props
}: Props) => {
  const { query } = useRouter();
  const { messages } = useLocale();
  const {
    view_all_msg,
    added_msg,
    add_to_cart_msg,
    from_msg,
    notification_on_msg,
    notify_me_msg,
    sold_out_msg,
  } = messages || {};
  const { width } = useWindowDimensions();

  const { locationDetails, countryArray } = useAppSelector(
    (state) => state.global
  );
  const user = useAppSelector((state) => state.user);
  const cart = useAppSelector((state) => state.cart);
  const [showBtnAnimation, setShowBtnAnimation] = useState(false);

  const [currencyDetails, setCurrencyDetails] =
    useState<CountryListInterface | null>(null);
  const [notifyMeStatus, setNotifyMeStatus] = useState(false);
  const [notifyMeLoading, setNotifyMeLoading] = useState(false);

  const { notifyProducts, handleNotifyProductClick } = useNotifyMeProducts();
  const addToCartByProductSKUHook = useAddToCartByProductSku();

  const discount = useMemo(() => {
    return product ? getDiscount(product.mrp, product.price) ?? 0 : 0;
  }, [product]);

  const plusPrice = useMemo(() => {
    return product && product?.plusDiscount
      ? Number(product.price) -
          Number(product.price) * Number(product.plusDiscount)
      : 0;
  }, [product]);

  const totalPlusDiscount = useMemo(() => {
    return product && product?.plusDiscount
      ? getDiscount(
          product.mrp,
          Number(product.price) -
            Number(product.price) * Number(product.plusDiscount)
        ) ?? 0
      : 0;
  }, [product]);

  const handleClickNotifyButton = useCallback(
    async (action: 'add' | 'remove') => {
      if (product?.productId) {
        setNotifyMeLoading(true);
        const productObj = {
          productId: product?.productId,
          title: product?.title,
          slug: product?.slug,
          image: product?.image,
          platform: product?.platform,
          price: product?.price ?? null,
          regionId: product?.regionId?.toString() ?? null,
        };
        const response = await handleNotifyProductClick(productObj, action);
        if (response) {
          setNotifyMeStatus(action === 'add');
        }
        setNotifyMeLoading(false);
      }
    },
    [handleNotifyProductClick, product]
  );

  useEffect(() => {
    if (notifyProducts && product?.slug) {
      setNotifyMeStatus(
        notifyProducts.map((p) => p.slug).includes(product?.slug)
      );
    }
  }, [notifyProducts, product?.slug]);

  const getCurrencyDetailsFromCurrency = useCallback(
    (currency: string) => {
      const tempCurrency = countryArray?.filter(
        (country) => country.currency === currency
      );
      setCurrencyDetails(tempCurrency?.[0]);
    },
    [countryArray]
  );

  useEffect(() => {
    if (query.currency) {
      getCurrencyDetailsFromCurrency(query.currency as string);
    }
  }, [query.currency, getCurrencyDetailsFromCurrency]);

  const renderProductCard = useMemo(() => {
    if (!product) return null;

    const productLink = storeId
      ? `/${product.slug}?seller=${storeId}`
      : `/${product.slug}`;
    const productImage =
      product.image || 'https://static.driffle.com/fallback_image.png';
    const price = product?.price;
    const currencySymbol =
      currencyDetails?.currency_symbol ?? locationDetails?.currency_symbol;
    const multiplier =
      currencyDetails?.multiplier ?? locationDetails?.multiplier;

    return (
      <ProductCardWrapper
        onMouseEnter={(e: any) => {
          e.preventDefault();

          if (
            showAddToCartBtn &&
            width &&
            width > getNumFromResolutionConstant(WIDTH.tabletMax)
          )
            setShowBtnAnimation(true);
        }}
        onMouseLeave={(e: any) => {
          e.preventDefault();

          if (showAddToCartBtn) setShowBtnAnimation(false);
        }}
        page={page}
      >
        <ProductCardContainer onClick={clickBubble || (() => {})}>
          <Link href={productLink} passHref prefetch={false}>
            <a>
              <ImageContainer>
                <OriginalImageContainer
                  style={{ filter: price ? 'none' : 'grayscale(100%)' }}
                >
                  <Image
                    layout="responsive"
                    height="280px"
                    width="200px"
                    objectFit="cover"
                    quality={75}
                    unoptimized
                    src={productImage}
                    alt={`Product Image - ${product.title}`}
                    loading="lazy"
                    onError={(e) => {
                      e.currentTarget.src =
                        'https://static.driffle.com/fallback_image.png';
                    }}
                  />
                </OriginalImageContainer>
                {!price && (
                  <OOSOverlay>
                    {notifyMeStatus ? (
                      <NotifyMeButtonActive
                        onClick={(e) => {
                          e.preventDefault();
                          handleClickNotifyButton('remove');
                        }}
                      >
                        {notifyMeLoading ? (
                          <LoaderCircle />
                        ) : (
                          <>
                            <MaskIcon
                              url="/icons/notifications-active.svg"
                              width="20px"
                              height="20px"
                              color="#000"
                              selected
                            />
                            {notification_on_msg}
                          </>
                        )}
                      </NotifyMeButtonActive>
                    ) : (
                      <NotifyMeButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleClickNotifyButton('add');
                        }}
                      >
                        {notifyMeLoading ? (
                          <LoaderCircle />
                        ) : (
                          <>
                            <MaskIcon
                              url="/icons/add-alert.svg"
                              width="20px"
                              height="20px"
                              color="#fff"
                              selected
                            />
                            {notify_me_msg}
                          </>
                        )}
                      </NotifyMeButton>
                    )}
                  </OOSOverlay>
                )}
              </ImageContainer>
            </a>
          </Link>
          <Link href={productLink} passHref prefetch={false}>
            <a style={{ height: '100%' }}>
              <DetailsContainer>
                <TooltipWithArrow title={<>{product?.title}</>}>
                  <ProductTitleNew>{product?.title}</ProductTitleNew>
                </TooltipWithArrow>
                <ProductInfo>
                  {product.regionName
                    ? product.regionName
                    : Array.isArray(product?.region)
                    ? getRegionFromRegionId(product?.region[0]?.toString())
                    : typeof product?.region === 'string'
                    ? product.region
                    : getRegionFromRegionId(product.regionId?.toString()) ??
                      '-'}
                </ProductInfo>

                {plusType &&
                Number(plusPrice) > 0 &&
                Number(product.plusDiscount) > 0 ? (
                  <SpecialPlusPriceFooter
                    price={price}
                    currencySymbol={currencySymbol}
                    multiplier={multiplier}
                    product={product}
                    discount={discount}
                    mrp={product.mrp}
                    plusPrice={plusPrice}
                    totalPlusDiscount={totalPlusDiscount}
                  />
                ) : (
                  <>
                    {/* plus pricing strip */}
                    {user.subscription === 'inactive' &&
                    price &&
                    product?.plusDiscount &&
                    Number(product?.plusDiscount) > 0 ? (
                      <PlusPricingStrip
                        price={price}
                        currencySymbol={currencySymbol}
                        multiplier={multiplier}
                        product={product}
                      />
                    ) : null}

                    {price ? <PriceFromText>{from_msg}</PriceFromText> : null}
                    <ProductBottomRow>
                      <ProductPrice>
                        {price ? (
                          displayCurrencyValue(
                            price,
                            currencySymbol,
                            multiplier
                          )
                        ) : (
                          <SoldOutMsg>{sold_out_msg}</SoldOutMsg>
                        )}
                      </ProductPrice>
                      {price && product.mrp && discount > 0 ? (
                        <DiscountBox>-{discount}%</DiscountBox>
                      ) : null}
                    </ProductBottomRow>
                  </>
                )}
              </DetailsContainer>
            </a>
          </Link>
        </ProductCardContainer>

        {!showAddToCartBtn && !showBtnAnimation ? null : (
          <HoverAddToCartBtn
            cart={cart}
            showBtnAnimation={showBtnAnimation}
            addToCartByProductSKUHook={addToCartByProductSKUHook}
            recommendationId={props.recommendationId}
            scenario={scenario}
            product={product}
          />
        )}
      </ProductCardWrapper>
    );
  }, [
    product,
    storeId,
    currencyDetails?.currency_symbol,
    currencyDetails?.multiplier,
    locationDetails?.currency_symbol,
    locationDetails?.multiplier,
    page,
    clickBubble,
    notifyMeStatus,
    notifyMeLoading,
    notification_on_msg,
    notify_me_msg,
    plusType,
    discount,
    user.subscription,
    from_msg,
    sold_out_msg,
    showAddToCartBtn,
    showBtnAnimation,
    cart,
    addToCartByProductSKUHook,
    props.recommendationId,
    scenario,
    width,
    handleClickNotifyButton,
  ]);

  return (
    <>
      {addToCartByProductSKUHook.displayItem ? (
        widthType === 'fixed' ? (
          <CardWrapper>{renderProductCard}</CardWrapper>
        ) : (
          renderProductCard
        )
      ) : null}
    </>
  );
};

export default memo(ProductCardG);
