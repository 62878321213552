import { useTheme } from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { useLocale } from '../../../contexts/LocalizationContext';
import { displayCurrencyValue } from '../../../helper/helper';
import { MaskIcon } from '../../Icons/Icons';
import Button from '../../NewButton/Button';
import {
  PlusDiscountBox,
  PlusProductPrice,
  SoldOutMsg,
} from '../ProductCardGStyle';
import {
  SpecialPlusPriceBuyBtn,
  SpecialPlusPriceFooterContainer,
  SpecialPlusPriceFooterPlusLogo,
  SpecialPlusPriceMrpStrikeText,
  SpecialPlusPriceTextRow,
} from '../ProductCardNewStyle';

interface Props {
  price: string | number | null;
  currencySymbol: string | undefined;
  multiplier: number;
  product: any;
  discount: number;
  totalPlusDiscount: number;
  plusPrice: number;
  mrp: number | null;
}

function SpecialPlusPriceFooter({
  price,
  currencySymbol,
  multiplier,
  product,
  discount,
  totalPlusDiscount,
  plusPrice,
  mrp,
}: Props) {
  const theme = useTheme();
  const { messages } = useLocale();
  const { buy_now_msg, from_msg, sold_out_msg } = messages || {};

  return (
    <SpecialPlusPriceFooterContainer>
      <SpecialPlusPriceFooterPlusLogo>
        <MaskIcon
          url="/logo/plus-logo.svg"
          width="28px"
          height="12px"
          margin="0 0"
          color={theme.mode === 'dark' ? '#fff' : COLORS.drifflePlus}
          selected
        />
      </SpecialPlusPriceFooterPlusLogo>
      <SpecialPlusPriceMrpStrikeText>
        {from_msg}{' '}
        <span>
          {!mrp ? null : displayCurrencyValue(mrp, currencySymbol, multiplier)}
        </span>
      </SpecialPlusPriceMrpStrikeText>
      <SpecialPlusPriceTextRow>
        <PlusProductPrice>
          {plusPrice ? (
            displayCurrencyValue(plusPrice, currencySymbol, multiplier)
          ) : (
            <SoldOutMsg>{sold_out_msg}</SoldOutMsg>
          )}
        </PlusProductPrice>
        {plusPrice && product.mrp && discount > 0 ? (
          <PlusDiscountBox>-{totalPlusDiscount ?? discount}%</PlusDiscountBox>
        ) : null}
      </SpecialPlusPriceTextRow>
      <SpecialPlusPriceBuyBtn style={{ marginTop: '6px' }}>
        <Button width="100%">{buy_now_msg}</Button>
      </SpecialPlusPriceBuyBtn>
    </SpecialPlusPriceFooterContainer>
  );
}

export default SpecialPlusPriceFooter;
